import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import ExploreMenu from '@components/fixed/exploreMenu';
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line
import cx from 'classnames';
import { SanityImage } from '@components/sanity/sanityImage';
import { Link as ScrollLink } from 'react-scroll';

// Components
import { PageMeta } from '@components/pageMeta';
import ModuleZone from '~moduleZone';
import Layout from '@components/layout';
import { AnchorNav } from '@components/nav/anchorNav';

import WhiteVector from '@svg/whitevector.svg';

const ServicePageTemplate = ({ data: { page } }) => {
  const { modules, banner, seo, anchorLinks, title, pageColour } = page || {};

  const navRef = React.useRef(null);
  const bannerRef = React.useRef(null);

  const [navActive, setNavActive] = React.useState(false);
  const [buttonHovered, setButtonHovered] = React.useState(false);

  //detect when navRef is at the top of the page
  React.useEffect(() => {
    const handleScroll = () => {
      if (navRef?.current?.getBoundingClientRect()?.top <= 0) {
        setNavActive(true);
      } else {
        setNavActive(false);
      }
    };
    const handleScroll2 = () => {
      if (bannerRef.current.getBoundingClientRect().bottom >= 0) {
        setNavActive(false);
      } else {
        setNavActive(true);
      }
    };
    anchorLinks &&
      window.addEventListener('scroll', handleScroll, { passive: true });
    anchorLinks &&
      window.addEventListener('scroll', handleScroll2, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', handleScroll2);
    };
  }, [navRef]);

  //get height of navRef
  const [navHeight, setNavHeight] = React.useState(0);
  React.useEffect(() => {
    setNavHeight(
      navRef.current ? navRef.current.getBoundingClientRect().height : 0,
    );
  }, [navRef]);

  const [windowHeight, setWindowHeight] = useState(
    typeof window !== 'undefined' && window.innerHeight,
  );

  useEffect(() => {
    setWindowHeight(typeof window !== 'undefined' && window.innerHeight);
  }, []);

  return (
    <Layout unPinHeader={true} pageColour={pageColour?.title}>
      <PageMeta {...seo} />
      <div
        ref={bannerRef}
        style={{ marginBottom: `${navHeight}px` }}
        className='relative z-20'>
        <div
          className='h-[50vh] md:h-screen w-full relative flex items-center justify-start text-white '
          id='top'>
          <SanityImage
            image={banner?.bgImage}
            className='absolute z-0 w-full h-full top-0 left-0 object-cover'
          />
          <div
            className={cx(
              'absolute top-0 left-0 w-full h-full bg-black bg-opacity-60 lg:bg-opacity-40',
              {
                'lg:bg-gradient-to-r from-black  to-transparent':
                  banner?.linearGradient,
              },
            )}
          />
          <div className='hidden md:block relative z-10  px-gutter md:max-w-[70vw]'>
            <h1 className='blockH4 '>{banner?.title}</h1>
            <h2 className=' blockH6 pt-8 pb-6  '>{banner?.subtitle}</h2>
            <ScrollLink
              to='top'
              offset={windowHeight}
              smooth={true}
              onMouseEnter={() => setButtonHovered(true)}
              onMouseLeave={() => setButtonHovered(false)}
              className={cx(
                `button-transparent cursor-pointer  w-[fit-content] flex transition-all ease-in-out duration-700`,
                {
                  'hover:bg-red/20': pageColour?.title === 'red',
                  'hover:bg-blue/20': pageColour?.title === 'blue',
                  'hover:bg-navy/20': pageColour?.title === 'navy',
                  'hover:bg-dark-red/20': pageColour?.title === 'dark-red',
                  'hover:bg-grey/70': pageColour?.title === 'grey',
                },
              )}>
              <div className='blockH8 font-medium  self-center inline-block pr-4 '>
                Read more
              </div>
              <div
                className={cx(
                  `inline-block bg-grey/50 p-3 rounded-full transition-all ease-in-out duration-700`,
                  {
                    'bg-red': pageColour?.title === 'red' && buttonHovered,
                    'bg-blue': pageColour?.title === 'blue' && buttonHovered,
                    'bg-navy': pageColour?.title === 'navy' && buttonHovered,
                    'bg-dark-red':
                      pageColour?.title === 'dark-red' && buttonHovered,
                    'bg-grey': pageColour?.title === 'grey' && buttonHovered,
                  },
                )}>
                <WhiteVector className='  w-4 h-4  ' />
              </div>
            </ScrollLink>
          </div>
        </div>
        <div
          className={`md:hidden relative z-10 mt-12 pb-8 lg:pb-16 px-gutter w-full text-${pageColour?.title}`}>
          <h1 className='blockH3 '>{banner?.title}</h1>
          <h2 className=' blockH5 pt-8 pb-6  '>{banner?.subtitle}</h2>
        </div>
        {/* Sticky nav */}
        {anchorLinks && (
          <AnchorNav
            navRef={navRef}
            anchorLinks={anchorLinks}
            navActive={navActive}
            title={title}
            pageColour={pageColour?.title}
            pageColourHex={pageColour?.value}
          />
        )}
      </div>

      <div className=' '>
        {modules && (
          <ModuleZone
            {...modules}
            pageColour={pageColour?.title}
            pageColourValue={pageColour?.value}
          />
        )}
      </div>
      <ExploreMenu pageColour={pageColour} />
    </Layout>
  );
};

export default ServicePageTemplate;

export const pageQuery = graphql`
  query standardServiceQuery($slug: String!) {
    page: sanityService(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      pageColour {
        title
        value
      }
      anchorLinks {
        ...AnchorLink
      }
      banner {
        title
        subtitle
        bgImage {
          ...Image
        }
        linearGradient
      }
      modules {
        ...ServiceModules
      }
    }
  }
`;
